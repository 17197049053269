<template>
  <div>
    <b-card>
      <b-card-title
        class="d-flex justify-content-between align-items-center flex-wrap"
      >
        <h3>Variantes de productos o servicios</h3>
        <b-button
          variant="success"
          class="mt-1 add-button-rounded mr-1"
          :to="{ name: 'add-variant' }"
        >
          <feather-icon icon="PlusIcon" size="18" />
        </b-button>
      </b-card-title>
      <b-row>
        <b-col>
          <variants-list-mobile
            :variants="variants"
            :deactiveVariant="deactiveVariant"
            :reactiveVariant="reactiveVariant"
            :handleShowEditOptionsVariantModal="
              handleShowEditOptionsVariantModal
            "
            :handleShowOptionsVariantModal="handleShowOptionsVariantModal"
          />
          <variants-table
            :variants="variants"
            :deactiveVariant="deactiveVariant"
            :reactiveVariant="reactiveVariant"
            :handleShowOptionsVariantModal="handleShowEditOptionsVariantModal"
          />
        </b-col>
      </b-row>
      <div class="mx-2 mb-2 mt-1">
        <pagination
          :pagination="pagination"
          :handlePagination="handlePagination"
          :loading="loading"
          :entriesPerPage.sync="entriesPerPage"
        />
      </div>
    </b-card>
    <edit-variant-options-modal
      :variant="variant"
      :handleDeleteOptionVariant="handleDeleteOptionVariant"
    />

    <variant-options-modal :selectedVariant="selectedVariant" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import VariantsTable from "@core/components/variants/VariantsTable.vue";
import EditVariantOptionsModal from "@core/components/variants/EditVariantOptionsModal.vue";
import VariantsListMobile from "@core/components/variants/VariantsList.vue";
import VariantOptionsModal from "@core/components/variants/VariantOptionsModal.vue";
import Pagination from "@/@core/components/Pagination.vue";

import { avatarText } from "@core/utils/filter";

export default {
  name: "VariantsList",
  components: {
    VariantsTable,
    EditVariantOptionsModal,
    VariantsListMobile,
    VariantOptionsModal,
    Pagination,
    ToastificationContent,
  },

  setup() {
    return {
      // Filter
      avatarText,
    };
  },

  data() {
    return {
      variant: null,
      loading: true,
      entriesPerPage: "10",
      selectedVariant: null,
    };
  },
  watch: {
    entriesPerPage() {
      this.loading = true;
      this.fetchVariants({
        by_active_status: "active",
        meta: {
          pagination: {
            page: this.pagination.page,
            per_page: this.entriesPerPage,
          },
        },
      })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const msg = error.response.data.messages;
          this.$swal({
            title: "ERROR!",
            text: `${msg}`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
  },

  computed: {
    ...mapGetters("variants", ["pagination", "variants"]),
  },

  created() {
    this.set_store_id(this.$route.params.id);
  },

  beforeMount() {
    this.fetchVariants({
      meta: {
        pagination: {
          per_page: this.entriesPerPage,
        },
      },
    }).then((response) => {
      this.loading = false;
    });
  },
  methods: {
    ...mapMutations("variants", ["set_store_id"]),
    ...mapActions("variants", [
      "fetchVariants",
      "cancelVariant",
      "activateVariant",
      "deleteOptionVariant",
    ]),
    handleShowEditOptionsVariantModal(variant) {
      this.variant = variant;
      this.$bvModal.show("bv-modal-edit-options-variants");
    },
    handleDeleteOptionVariant(variant) {
      this.$swal({
        title: "¿Estás seguro?",
        text: "La opción de variante se eliminará y dejará de estar disponible para crear nuevos productos",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteOptionVariant({
            id: variant.id,
          })
            .then((response) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                title: "Eliminada con exito!",
                text: "Opción de variante eliminada con exito!",
                props: {
                  title: "Éxito",
                  text: "Opción de variante agregada",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.fetchVariants({
                meta: {
                  pagination: {
                    per_page: this.entriesPerPage,
                  },
                },
              }).then((res) => {
                this.variant = this.variants.find(
                  (variant) => variant.id === this.variant.id
                );
              });
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Error",
                  text:
                    error.response.data.messages[0] ||
                    "Opción de variante no eliminada",
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    deactiveVariant(variant_id) {
      this.loading = true;
      this.variant = { id: variant_id, active_status: false };

      this.$swal({
        title: "¿Estás seguro?",
        text: "La variante se desactivará y dejará de estar disponible para crear nuevos productos",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "seee, bórralo!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.cancelVariant(this.variant)
            .then((response) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Éxito",
                  text: "Variante cancelada con exito!",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.fetchVariants({
                meta: {
                  pagination: {
                    per_page: this.entriesPerPage,
                  },
                },
              });
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Error",
                  text:
                    error.response.data.messages[0] || "Variante no cancelada",
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },

    reactiveVariant(variant_id) {
      this.loading = true;
      this.variant = { id: variant_id, active_status: true };

      this.$swal({
        title: "¿Estás seguro?",
        text: "La variante se reactivará y estará disponible para crear nuevos productos",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "seee, actívala!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.activateVariant(this.variant)
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Éxito",
                  text: "Variante reactivada con exito!",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.fetchVariants({
                meta: {
                  pagination: {
                    per_page: this.entriesPerPage,
                  },
                },
              });
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Error",
                  text:
                    error.response.data.messages[0] || "Variante no reactivada",
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    handleShowOptionsVariantModal(variant) {
      this.selectedVariant = variant;
      this.$bvModal.show("bv-modal-options-variants");
    },

    handlePagination({ page, per_page }) {
      this.loading = true;
      this.fetchVariants({
        by_active_status: "active",
        meta: {
          pagination: {
            page,
            per_page,
          },
        },
      }).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.gutter-sm {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.delete-button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-button-rounded {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.options-flex {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.variants-table {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.variants-list {
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
}
</style>
